export const sizeConsts = {
    containerFormWdth: '390px',
    containerFormMobiWidth: '300px',
    containerFormTabletWidth: '350px',
    containerFormHeigth: '50vh',
    pageBigDesktop: '1360px',
    pageDesktop: '1200px',
    pageTablet: '992px',
    pageSmallTablet: '768px',
    pageMobile: '545px',
    containerWidthMobile: '100%',
    containerWidthBigMobile: '100%',
    containerSmallTablet: '100%',
    containerTablet: '100%',
    containerDesktop: '100%',
    containerBigDesktop: '1270px',
    


}